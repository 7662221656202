import { useEffect, useState } from 'react';
import './al-ansar-admission.css'
import BgImage from './bg_admission_alansar.png'
import VCSign from './al_ansar_vc_sign.png'

const AlAnsarAdmissionLetter = (props) => {
    const applicantCourse = props.data.applicantCourse[0];
    const applicantInfo = props.data.applicantInfo[0];
    const decison = props.data.decison;
    const school = props.data.school
    const today = new Date();
    const faculty = props.data.facultyDetails[0]
    const title = props.data.decison.type === "Conditional" ? "CONDITIONAL OFFER OF ADMISSION" : "OFFER OF PROVISIONAL ADMISSION";
    const imagewidth = props.data.school.shortCode === "OUB" ? "120px" : "40px";
    let cons_ = []
    if (props.data.decison.con1 !== "") {
        cons_.push(...[props.data.decison.con1])
    }
    if (props.data.decison.con2 !== "") {
        cons_.push(...[props.data.decison.con2])
    }
    if (props.data.decison.con3 !== "") {
        cons_.push(...[props.data.decison.con3])
    }
    if (props.data.decison.con4 !== "") {
        cons_.push(...[props.data.decison.con4])
    }
    if (props.data.decison.con5 !== "") {
        cons_.push(...[props.data.decison.con5])
    }
    if (props.data.decison.con6 !== "") {
        cons_.push(...[props.data.decison.con6])
    }
    const [data, setData] = useState([props.data])

    return (
        <div>
            {
                data.length > 0 &&
                <div >
                    {
                        props.data.decison.type === "Conditional" ?
                            <div  className="body" style={{
                                backgroundImage: `url(${BgImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundColor: 'transparent !important',
                                paddingBottom: '70px'
                            }}
                               
                                ref={props.componentRef}
                            >
                                <div className="table-wrapper"  >
                                    <div>
                                        <br />
                                        <p className='p-text' >
                                            APP/{decison.admissionSemester}/{decison.applicant_id}
                                            <br />
                                            {applicantInfo.Surname} {applicantInfo.MiddleName} {applicantInfo.FirstName}
                                            <br />
                                            {applicantInfo.Address}
                                            <br />
                                            {applicantInfo.StateOfOrigin}, {applicantInfo.Nationality}
                                            <br />
                                            {today.getDate()}. {today.getMonth() + 1}. {today.getFullYear()}
                                        </p>
                                        <p>Dear {applicantInfo.FirstName},</p>
                                    </div>
                                    <div className="title">{title}</div>
                                    <div style={{ marginTop: "-5px" }} >
                                        <p>
                                            Further to your application to study at {school.name.split("|")[0]}, I am pleased to notify you that you have been offered Conditional Admission into the Degree Programme - <strong style={{fontStyle: 'italic'}}>{applicantCourse.CourseName}</strong> starting from 2022/2023 Academic Session due to begin in September, 2022.</p>
                                        <p>In order to secure your place, you are advised to make payment of your tuition fee of <strong>Seven Hundred and Eighty Five Thousand Naira Only (N785,000.00)</strong> to cover two semesters using the following account details:</p>
                                        <strong>
                                            <p>Account Name: Al-Ansar University<br />
                                                Bank: Zenith Bank PLC<br />
                                                Account: 1016506533
                                            </p>
                                        </strong>
                                        <p>
                                            Meanwhile, you will need to provide the following documents to fully confirm your admission:<br/>
                                            {cons_.length > 0 &&
                                                cons_.map((x, i) => {
                                                    return (
                                                        <span key={i}>&emsp;{i + 1}. {x}</span>
                                                    )
                                                })
                                            }                </p>

                                        Meanwhile, note that the admission is made subject to:<br />
                                        1. The regularization of admission by Jamb; and<br />
                                        2. Your participation in the Matriculation which is scheduled to take place in early November, 2022<br /><br />
                                        Congratulations!
                                        <br />
                                        <img src={VCSign} width="180px" alt="vc sign" />
                                        <br />
                                        {school.viceChancellor}<br />
                                        Vice-Chancellor
                                    </div>
                                   <br/>
                                </div>
                            </div>
                            :

                            <div className="body" style={{
                                backgroundImage: `url(${BgImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundColor: 'transparent !important',
                                paddingBottom: '20px'
                            }} ref={props.componentRef} >
                                <div className="table-wrapper" >
                                    <div>
                                        <br />
                                        <p className='p-text' >
                                            APP/{decison.admissionSemester}/{decison.applicant_id}
                                            <br />
                                            {applicantInfo.Surname} {applicantInfo.MiddleName} {applicantInfo.FirstName}
                                            <br />
                                            {applicantInfo.Address}
                                            <br />
                                            {applicantInfo.StateOfOrigin}, {applicantInfo.Nationality}
                                            <br />
                                            {today.getDate()}. {today.getMonth() + 1}. {today.getFullYear()}
                                        </p>
                                        <p>Dear {applicantInfo.FirstName},</p>
                                    </div>
                                    <div className="title">{title}</div>
                                    <div style={{ marginTop: "-5px" }} >
                                        Following your application for admission into {school.name.split("|")[0].trim()}, I am pleased to inform you that you have been offered admission into <strong>{props.data.decisionDetails[0]?.AdmissionLevel} Level</strong> to read <strong>{applicantCourse.CourseName}</strong>  programme in the <strong>{faculty.FacultyName.replace("Faculty", "College")}</strong>. The offer of admission is made subject to the following conditions:<br />
                                        <ol type="a">
                                            <li>You possess a minimum of 5 ‘O’ Level Credits including English, Mathematics and other three (3) relevant credits in your course of study;</li>
                                            <li>Your admission is subject to obtaining the minimum entry requirements for the programme and approval by JAMB;</li>
                                            <li>Your particulars provided in your application are correct in every respect;</li>
                                            <li>You are to present original credentials listed in the application for scrutiny during registration. If it is discovered at any time that you do not possess any of the qualifications which you claim to have obtained or any of the information you provided are false , you will be required to with draw from the University;</li>
                                            <li>You are to report to the Academic Division for Central Registration within the stipulated time. No alteration or change of name would be allowed after the registration;</li>
                                            <li>You must be certified mentally fit, through a medical examination conducted by the University clinic;</li>
                                            <li>You should be at least 16 years old (evidence of  date of birth will be required;</li>
                                            <li>This offer is not transferable to any other person; and</li>
                                            <li>Registration date to be announced later.</li>

                                        </ol>
                                        <br />
                                        <span style={{ marginLeft: '50px' }}>Please accept my congratulations on your admission!</span>  <br />
                                        Yours faithfully
                                        <br />
                                        <img src={VCSign} width="180px" alt="vc sign" />
                                        <br />
                                        Malam Bukar Usman, FCIA, f. inst. A. M<br />
                                        Registrar
                                    </div>
                                </div>
                            </div>

                    }

                </div>
            }
        </div >
    )
}
export default AlAnsarAdmissionLetter