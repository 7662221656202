import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Loader from "../../../common/loader/loader";
import PageHeader from "../../../common/pageheader/pageheader";
import axios from "axios";
import {serverLink} from "../../../../resources/url";
import {toast} from "react-toastify";
import "react-select2-wrapper/css/select2.css";
import ReportTable from "../../../common/table/report_table";
import { showConfirm } from "../../../common/sweetalert/sweetalert";

function DeleteResult(props) {
    const token = props.loginData.token;

    const [isLoading,setIsLoading] = useState(false);
    const [studentID, setStudentID] = useState("");
    const [resultList, setResultList] = useState([]);
    const columns = ["S/N", "Student ID", "Module Code", "Module Name", "CA", "Exam", "Total", "Grade", "Decision", "Semester", "Action"]
    const [tableData, setTableData] = useState([]);

    const handleChange = (e) => {
        const value = e.target.value;
        setStudentID(value)
    }

    const searchResult = async () => {
        setIsLoading(true);
        const sendData = {
            StudentID: studentID
        }

        await axios.post(`${serverLink}staff/assessment/exam/student/result`, sendData, token)
            .then(res => {
                const data = res.data;
                if (data.message === 'success') {
                    let rows = [];
                    if (data.data.length > 0) {
                        data.data.map((row, index) => {
                            rows.push([
                                (index+1), row.StudentID, row.ModuleCode, row.ModuleTitle, row.CAScore,
                                row.ExamScore, row.Total, row.StudentGrade, row.Decision, row.SemesterCode,
                                <i className="fa fa-trash text-danger" onClick={() => handleDelete(row)} />
                            ])
                        })
                    } else {
                        toast.error("No result found for the selected student")
                    }
                    setTableData(rows);
                    setIsLoading(false)
                } else {
                    toast.error("Error fetching processing data")
                }
            })
            .catch(err => {
                toast.error("NETWORK ERROR")
            })
    }

    const handleDelete = async (result) => {
        showConfirm(
            'RESULT DELETION?',
            `Are you sure you want to delete the following result
                    Student ID: ${result.StudentID}
                    Module Code: ${result.ModuleCode}
                    Module Name: ${result.ModuleTitle}
                    Total: ${result.Total}
                    Grade: ${result.StudentGrade}
                    Decision: ${result.Decision}
                    Semester: ${result.SemesterCode}
                  `,
            `error`,
            '',
            ["Cancel", "Yes, Delete"]
        ).then(async (isConfirm) => {
            if (isConfirm) {
                await axios.delete(`${serverLink}staff/assessment/exam/delete/result/${result.EntryID}/${props.loginData.StaffID}`, token)
                    .then(res => {
                        if (res.data.message === 'success') {
                            toast.success("Result deleted successfully!");
                            searchResult();
                        } else {
                            toast.error("Something went wrong deleting result. Please try again!")
                        }
                    })
                    .catch(err => {
                        toast.error("Failed to delete. Network issue. Please try again!")
                    })
            }
        })
    }

    return isLoading ? <Loader/> : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader title={"Delete Result"} items={["Assessment", "Exams & Records", "Delete Result"]}/>
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11">
                                <div className="form-group">
                                    <input type="text" id="StudentID" className="form-control" placeholder="Enter the student ID" value={studentID} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="col-md-1">
                                <button className="btn btn-primary w-100" onClick={searchResult}>Search</button>
                            </div>
                        </div>

                        {
                            tableData.length > 0 &&
                            <div className="row pt-5">
                                <ReportTable columns={columns} data={tableData} height={'800px'} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails[0],
    };
};

export default connect(mapStateToProps, null)(DeleteResult);
